import styles from "./Footer.module.css";
import { WhatsApp } from "@mui/icons-material";
import Container from "../../Components/Container/Container";
import PrimarySection from "../../Components/Section/PrimarySection";
import PartnerIcon from "./PartnerIcon";
import SocialLink from "../../Components/SocialLink/SocialLink";
import { FaStripe, FaGooglePay, FaApplePay } from "react-icons/fa";
import { FaTiktok, FaFacebookF } from "react-icons/fa";
import { RiInstagramFill, RiVisaLine, RiMastercardFill } from "react-icons/ri";
import { IoMdMail } from "react-icons/io";
import { SiIata } from "react-icons/si";

const socialLinks = [
  { name: "Facebook", icon: <FaFacebookF />, href: "" },
  { name: "Instagram", icon: <RiInstagramFill />, href: "" },
  { name: "Tiktok", icon: <FaTiktok />, href: "" },
  { name: "Email", icon: <IoMdMail />, href: "" },
];

export default function Footer() {
  return (
    <footer>
      <PrimarySection py="0" pb="50px">
        <Container className={styles.footerContainer}>
          {/* <SocialLinks /> */}
          <PartnerImages />
          <CopyrightText />
          <Chat />
        </Container>
      </PrimarySection>
    </footer>
  );
}

function PartnerImages() {
  return (
    <div className="col-12 col-md-6 col-lg-12 row justify-content-center">
      <PartnerIcon icon={<FaStripe />} />
      <PartnerIcon icon={<FaGooglePay />} />
      <PartnerIcon icon={<RiVisaLine />} />
      <PartnerIcon icon={<RiMastercardFill />} />
      <PartnerIcon icon={<SiIata />} />
    </div>
  );
}

function SocialLinks() {
  return (
    <div className="col-12 row justify-content-center">
      {socialLinks.map((social) => (
        <SocialLink icon={social.icon} title={social.name} />
      ))}
    </div>
  );
}

function CopyrightText() {
  return (
    <div className={styles.copyrightText}>
      © 2024 TRAVL Technologies. All Rights Reserved.
    </div>
  );
}

function Chat() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=971569964924&text=Hi.%20I%20need%20a%20dummy%20ticket."
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.WhatsAppIcon}>
        <WhatsApp fontSize="medium" />
      </div>
    </a>
  );
}
