import styles from "./PaymentSuccess.module.css";
import PrimarySection from "../../Components/Section/PrimarySection";
import PageTitle from "../../Components/PageTitle/PageTitle";
import img from "./sample-ticket.png";

export default function PaymentSuccess() {
  return (
    <PrimarySection py="100px">
      <div className="col-10 col-lg-8 mx-auto">
        <h1 className={styles.title}>Thank You for Your Booking!</h1>
        <div className={styles.Text}>
          <p>
            Your payment has been successfully processed. We appreciate your
            trust in us for your travel needs.
          </p>
          <h2>What next?</h2>
          <p>
            You’ll soon receive a confirmation email with your dummy ticket
            attached. Please check your inbox and spam folder. Ensure all the
            details on your dummy ticket are correct. If you spot any error or
            wrong information, contact us immediately.
          </p>
        </div>
      </div>
    </PrimarySection>
  );
}
