import { FaStripe } from "react-icons/fa";
import styles from "./Footer.module.css";

export default function PartnerIcon({ icon }) {
  return (
    <div className={styles.partnerIconDiv}>
      <span className={styles.partnerIcon}>{icon}</span>
    </div>
  );
}
