import { useState, useEffect } from "react";
import styles from "./Container.module.css";

export default function Container(props) {
  const containerStyle = {
    paddingTop: props.pt || props.py,
    paddingBottom: props.pb || props.py,
    marginTop: props.mt || props.my,
    marginBottom: props.mb || props.my,
  };

  const [classNames, setClassName] = useState(
    `col-11 mx-auto m-0 ${props.className} ${styles.container}`
  );

  useEffect(() => {
    setClassName(
      `col-11 mx-auto m-0 ${props.className} ${styles.container} ${styles.load}`
    );
  }, [props.className]);

  return (
    <div className={classNames} style={containerStyle}>
      {props.children}
    </div>
  );
}
